.leaflet-container{
  /*width: 100vw;*/
  height: 50vh;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.info-window {
  color: black;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding:5px;
  text-align: center;
}

.info-window a {
  color: white;
}
.infolink{
  display: inline-block;
  border:1px solid deepskyblue;
  padding:5px;
  border-radius:5px;
  text-align:center;
  font-size:16px;
  font-weight:bold;
  background-color:crimson;
  width: 40%;
}
.infolink:hover{
  background-color:lightcoral;
}
.infoname{
  font-size:18px;
  font-weight:bold;
}

.pac-target-input {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 20rem;
  padding: 0.5rem;
  z-index: 1;
}

.lat-lng {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.lat-lng input {
  margin: 0px 0.5rem;
  padding: 0.5rem;
}
div.infoaddres{
  color:red;
}
span.infoaddress{
  color:darkmagenta;
  font-size:16px;
}
.MuiAppBar-colorPrimary{
  background-color: red;
}
.placetitle{
  font-weight: bold;
}
.openinghours{
  list-style:square;
  font-size: 14px;
}
.btnprint{
  width: 100%;
}
