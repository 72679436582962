.searchInput {
    position: absolute;
    top: 0.6rem;
    left: 3.85rem;
    /*right: 1rem;*/
    height: 2.5rem;
    width: 15rem;
    margin: 0;
    padding: 0;
    padding-left: 0.5rem;
    font-family: sans-serif;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}

/* Hide label visually. To use for A11y only. */
label {
    z-index: -1000;
}

.suggestions {
    position: absolute;
    top: 6rem;
    left: 1.85rem;
    width: 20.5rem;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    overflow: auto;
    background-color: white;
    font-size: 0.875rem;
    list-style: none;
    border-radius:5px;
}

.suggestions > li {
    display: flex;
    align-items: center;
    height: 2.125rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    outline: none;
    cursor: default;
}

.suggestions > li:hover,
.suggestions > li:focus-visible {
    background-color: #f0f0f0;
}

.suggestions > li > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
